import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-799be150"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "messageItemWrap" }
const _hoisted_2 = { class: "user" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["src"]
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { class: "videoMsg" }
const _hoisted_10 = ["src"]
const _hoisted_11 = ["id", "src", "poster"]
const _hoisted_12 = { key: 4 }
const _hoisted_13 = { key: 5 }
const _hoisted_14 = { key: 6 }
const _hoisted_15 = { class: "time" }
const _hoisted_16 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Popover = _resolveComponent("Popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Popover, {
      show: _ctx.leftMsg.isShowMenu,
      "onUpdate:show": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.leftMsg.isShowMenu) = $event)),
      actions: _ctx.leftMsg.actions,
      onSelect: _ctx.leftMsg.onSelect,
      placement: "top-end",
      theme: "dark",
      trigger: "manual"
    }, {
      reference: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.msg.from), 1),
        _createElementVNode("div", {
          class: "messageItem",
          onTouchstart: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.leftMsg.showMenu && _ctx.leftMsg.showMenu(...args))),
          onTouchend: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.leftMsg.end && _ctx.leftMsg.end(...args)))
        }, [
          (_ctx.msg.type === _ctx.leftMsg.msgType.txt)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("span", {
                  innerHTML: _ctx.msg.msg
                }, null, 8, _hoisted_4),
                (_ctx.msg.modifiedInfo)
                  ? (_openBlock(), _createBlock(_component_Tag, {
                      key: 0,
                      style: {"margin-left":"10px"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Modified")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : (_ctx.msg.type === _ctx.leftMsg.msgType.img)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("img", {
                    class: "imgMsg",
                    src: _ctx.msg.thumb || _ctx.msg.url,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.leftMsg.previewImg(_ctx.msg.url)))
                  }, null, 8, _hoisted_6)
                ]))
              : (_ctx.msg.type === _ctx.leftMsg.msgType.file)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", {
                      class: "fileMsg",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.leftMsg.downloadAttach(_ctx.msg.url)))
                    }, [
                      _createTextVNode(_toDisplayString(_ctx.msg.filename), 1),
                      _createVNode(_component_Icon, {
                        class: "icon",
                        size: "18",
                        name: "down"
                      })
                    ])
                  ]))
                : (_ctx.msg.type === _ctx.leftMsg.msgType.video)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("span", null, [
                          _createTextVNode("封面： "),
                          _createElementVNode("img", {
                            style: {"width":"100px"},
                            src: `${_ctx.msg.thumb}`
                          }, null, 8, _hoisted_10)
                        ]),
                        _createElementVNode("video", {
                          id: _ctx.msg.id,
                          src: _ctx.msg.url,
                          poster: _ctx.msg.thumb,
                          controls: "",
                          onError: _cache[2] || (_cache[2] = ($event: any) => (_ctx.leftMsg.onVideoError(_ctx.msg.id, _ctx.msg.url)))
                        }, null, 40, _hoisted_11)
                      ])
                    ]))
                  : (_ctx.msg.type === _ctx.leftMsg.msgType.custom)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("div", null, " 自定义消息 " + _toDisplayString(_ctx.msg.customEvent) + _toDisplayString(JSON.stringify(_ctx.msg.customExts)), 1),
                        (_ctx.msg.modifiedInfo)
                          ? (_openBlock(), _createBlock(_component_Tag, {
                              key: 0,
                              style: {"margin-left":"10px"}
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Modified")
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]))
                    : (_ctx.msg.type === _ctx.leftMsg.msgType.cmd)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          _createElementVNode("div", null, "cmd消息 action: " + _toDisplayString(_ctx.msg.action), 1)
                        ]))
                      : (_ctx.msg.type === _ctx.leftMsg.msgType.combine)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createElementVNode("div", {
                              class: "combine",
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.leftMsg.getCombineMsg(_ctx.msg)))
                            }, [
                              _createElementVNode("p", null, "title: " + _toDisplayString(_ctx.msg.title), 1),
                              _createElementVNode("p", null, "summary: " + _toDisplayString(_ctx.msg.summary), 1),
                              _createElementVNode("p", null, "compatibleText: " + _toDisplayString(_ctx.msg.compatibleText), 1)
                            ])
                          ]))
                        : _createCommentVNode("", true),
          (_ctx.msg.broadcast)
            ? (_openBlock(), _createBlock(_component_Tag, { key: 7 }, {
                default: _withCtx(() => [
                  _createTextVNode("广播消息: " + _toDisplayString(_ctx.msg.broadcast), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ], 32),
        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.leftMsg.formatTime(_ctx.timestamp, "YYYY-MM-DD hh:mm:ss")), 1),
        (_ctx.isPinned)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createVNode(_component_Tag, { style: {"margin-left":"10px"} }, {
                default: _withCtx(() => [
                  _createTextVNode("Pinned")
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["show", "actions", "onSelect"])
  ]))
}