
import contenteditable from "vue-contenteditable";
import { Options, Vue, setup } from "vue-class-component";
import { ref } from "vue";
@Options({
  emits: ["send"],
  components: {
    contenteditable
  }
})
export default class Contact extends Vue {
  ipt = setup(() => {
    const txt = ref("");

    const handleEnter = () => {
      if (txt.value) {
        this.$emit("send", txt.value);
      }
    };

    const clear = () => {
      txt.value = " ";
    };

    const mergeTxt = (str: string) => {
      txt.value = `${txt.value}${str}`;
    };

    return {
      txt,
      handleEnter,
      clear,
      mergeTxt
    };
  });
}
