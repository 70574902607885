import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e7f31662"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inputWrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_contenteditable = _resolveComponent("contenteditable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_contenteditable, {
      class: "input",
      tag: "div",
      modelValue: _ctx.ipt.txt,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ipt.txt) = $event)),
      noHTML: false,
      noNL: true,
      onReturned: _ctx.ipt.handleEnter,
      placeholder: "请输入"
    }, null, 8, ["modelValue", "onReturned"])
  ]))
}